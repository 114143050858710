import { Stack } from "@mui/material";
import PaymentCard from "./PaymentCard";

const brandImages = {
  VISA: {
      background: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/black.png",
      logo: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/visa.png",
      right: "0%",
      marginRight: "5px"
  },
  ECMC: {
      background: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/blue.png",
      logo: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/master_card.png",
      right: "-6%",
      marginRight: "0px"
  },
  MAESTRO: {
      background: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/green.png",
      logo: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/maestro.png",
      right: "-6%",
      marginRight: "0px"
  }
};

const genericBrandImages = {
  background: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/generic-background.png",
  logo: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/generic-logo.png",
  right: "0px",
  marginRight: "10px"
};


function PaymentCardList({ paymentTokens }) {
  return (
    <Stack
      sx={{
        alignItems: "left",
        justifyContent: "left",
      }}
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
    >
      {paymentTokens.map((paymentToken) => {
        const brand = paymentToken.card.brand in brandImages ? 
          brandImages[paymentToken.card.brand] : genericBrandImages;
        return (
        <PaymentCard
          key={paymentToken.paymentTokenId}
          paymentToken={paymentToken}
          brandImages={brand}
        />
      )})}
    </Stack>
  );
};

export default PaymentCardList;